<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Abril 2024                                                      ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
  <div class="contenido">
    <section class="d-flex">
      <!-- Filtrar por tipo de régimen -->
      <v-select class="me-2 filtro" v-model="buscar.regimen" label="Régimen" :items="regimenes" outlined dense
        hide-details :menu-props="{ offsetY: true }">
      </v-select>
      <!-- Filtrar por Nivel -->
      <v-autocomplete class="me-2 filtro" v-if="buscar.regimen === 'RC'" v-model="buscar.nivel" label="Nivel"
        :items="niveles" outlined dense hide-details :menu-props="{ offsetY: true }">
      </v-autocomplete>
      <!-- Filtrar por año -->
      <v-text-field class="me-2 filtro" v-model="buscar.ano" label="Año" outlined dense hide-details></v-text-field>

      <!-- Botón para agregar copago -->
      <div>
        <v-tooltip left color="success" :open-on-focus="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small fab color="success" v-bind="attrs" v-on="on" @mousedown.prevent="dialogoCopago = true">
              <v-icon> add </v-icon>
            </v-btn>
          </template>
          <span>Agregar</span>
        </v-tooltip>
      </div>
    </section>

    <!-- Copagos listados en v-cards -->
    <v-row class="ma-0" style="height: 75vh;">
      <v-col cols="12" md="12" class="mt-4 pa-0 contenedor-card">
        <v-card outlined class="mb-1 elevation-2" v-for="item in cardCopago.items" :key="item.ano">
          <v-card-text>
            <v-row>

              <!-- Primer columna con la información general -->
              <v-col cols="12" md="3" style="color: black;" class="d-flex align-center">
                <v-row class="ps-10">
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Año:</span>{{ item.ano }}
                  </v-col>
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Incremento: </span> {{ parseFloat(item.incremento).toFixed(2) }}%
                  </v-col>
                  <v-col cols="12" md="12" class="pa-1">
                    <span class="info-card">Estado: </span> <span class="font-weight-black"
                      :style="item.eliminado === false ? 'color: green' : 'color: red'">
                      {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Segunda columna con la tabla -->
              <v-col cols="12" md="6" align="center">
                <v-simple-table dense class="elevation">
                  <thead>
                    <tr>
                      <th v-if="buscar.regimen != 'RS'" class="text-center" width="25%">NIVEL</th>
                      <th class="text-center" width="25%">EVENTO</th>
                      <th class="text-center" width="25%">TOPE POR EVENTO</th>
                      <th class="text-center" width="25%">TOPE POR AÑO</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="nivel in item.niveles" :key="nivel.nivelValue">
                      <td v-if="buscar.regimen != 'RS'" class="text-center">{{ nivel.nivelValue }}</td>
                      <td class="text-center">{{ parseFloat(nivel.evento).toFixed(2) }}%</td>
                      <td class="text-center">$ {{ formatoPrecio(nivel.topeEvento) }}</td>
                      <td class="text-center">$ {{ formatoPrecio(nivel.topeAnual) }}</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>

              <!-- Tercera columna con las acciones -->
              <v-col cols="12" md="3" align="center" class="d-flex align-center">
                <v-row class="pe-10">
                  <v-col cols="12" md="12" class="pa-0">
                    <span class="info-card">Acciones</span>
                  </v-col>
                  <v-col cols="12" md="12" class="pa-0">
                    <v-tooltip bottom :color="item.eliminado === false ? 'orange' : 'gray'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon large v-on="on" class="ms-2 me-2" @click="item.eliminado === false && abrirDialogoEditar(item)">
                          <v-icon size="30px" :color="item.eliminado === false ? 'orange' : 'gray'">border_color</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.eliminado === false ? 'Editar' : 'Copago inactivo' }}</span>
                    </v-tooltip>

                    <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'gray'">
                      <template v-slot:activator="{ on }">
                        <v-btn icon large class="ms-2 me-2" v-on="on" @click="item.eliminado === false && abrirDialogoEstado(item)">
                          <v-icon size="30px" :color="item.eliminado === false ? 'error' : 'gray'">person_add_disabled</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ item.eliminado === false ? 'Inactivar' : 'Copago inactivo' }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Paginado -->
    <v-row class="paginado">
      <span class="me-8">Items por página:</span>
      <div class="select">
        <v-select class="opciones" hide-details v-model="cardCopago.itemsPerPage" :menuProps="{ maxWidth: 49.97 }"
          :items="cardCopago.opciones">
        </v-select>
      </div>

      <span style="margin: 0 28px;"> {{ rangoItems }} de {{ cardCopago.totalElements }}</span>
      <v-btn icon :disabled="cardCopago.page === 1" @click="cardCopago.page = 1">
        <v-icon>first_page</v-icon>
      </v-btn>
      <v-btn icon :disabled="cardCopago.page === 1" @click="cardCopago.page = cardCopago.page - 1">
        <v-icon>chevron_left</v-icon>
      </v-btn> 
      {{ cardCopago.page }}
      <v-btn icon :disabled="cardCopago.page === cardCopago.totalPage" @click="cardCopago.page = cardCopago.page + 1">
        <v-icon>chevron_right</v-icon>
      </v-btn>
      <v-btn icon :disabled="cardCopago.page === cardCopago.totalPage" @click="cardCopago.page = cardCopago.totalPage">
        <v-icon>last_page</v-icon>
      </v-btn>
    </v-row>

    <v-dialog v-model="dialogoCopago" transition="dialog-bottom-transition" max-width="32rem" persistent :editar="false">
      <!-- Mensaje -->
      <v-card v-if="!todosInactivos && !editar">
        <v-card-title class="d-flex justify-end pa-1 pb-0">
          <v-btn small icon @click="dialogoCopago = false"><v-icon color="primary">close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <div class=" d-flex mb-2 mensaje">
            <v-icon class="ma-2" color="primary">info</v-icon>
            <span class="d-flex align-center pa-1">Para crear un copago debe inactivar el del año anterior.</span>
          </div>
        </v-card-text>
      </v-card>

      <!-- Formulario -->
      <v-card v-else>
        <v-card-title class="encabezado"> {{ tituloDialogo }} </v-card-title>
        <v-card-text class="pt-5">
          <v-form ref="copagoForm">
            <div class="d-flex">
              <!-- Campo para almacenar el año del copago -->
              <v-text-field class="me-2" v-model="copago.ano" label="Año" dense outlined :disabled="editar"
                :error-messages="validateYear" :rules="rules.required" @keypress="soloNumeros" @paste.prevent
                @input="validarAno()">
              </v-text-field>

              <!-- Campo para ingresar el porcentaje de incremento -->
              <v-text-field v-model="copago.incremento" label="Incremento (%)" dense outlined
                :error-messages="validatePercentage" :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <!-- Régimen contributivo -->
            <span class="text-h6 regimen">Régimen Contributivo</span>
            <div class="linea mb-3"></div>

            <div class="d-flex filas" v-for="(nivel, index) in copago.niveles" :key="index">
              <span class="me-2 nivel">Nivel {{ nivel.nivel }}</span>
              <!-- Campo para ingresar el porcentaje por evento -->
              <v-text-field class="me-2" v-model="nivel.evento" label="Evento (%)" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>

              <!-- Campo para ingresar el tope máximo por evento -->
              <v-text-field class="me-2" v-model="nivel.topeEvento" label="Tope evento" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>

              <!-- Campo pa ingresar el tope máximo por año -->
              <v-text-field v-model="nivel.topeAnual" label="Tope anual" dense outlined :rules="rules.required"
                @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <!-- Régimen subsidiado -->
            <span class="text-h6 regimen">Régimen Subsidiado</span>
            <div class="linea"></div>

            <div class="d-flex mt-3 filas">
              <!-- Campo para ingresar el porcentaje por evento -->
              <v-text-field class="me-2" v-model="copago.regimenSubsidiado.evento" label="Evento (%)" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>

              <!-- Campo para ingresar el tope máximo por evento  -->
              <v-text-field class="me-2" v-model="copago.regimenSubsidiado.topeEvento" label="Tope evento" dense outlined
                :rules="rules.required" @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>

              <!-- Campo pa ingresar el tope máximo por año -->
              <v-text-field v-model="copago.regimenSubsidiado.topeAnual" label="Tope anual" dense outlined :rules="rules.required"
                @keypress="puntoYNumeros" @paste.prevent>
              </v-text-field>
            </div>

            <!-- Mensaje -->
            <div class=" d-flex mb-2 mensaje">
              <v-icon class="ma-2" color="primary">info</v-icon>
              <span class="d-flex align-center">Verifique los topes con la norma gubernamental antes de guardar.</span>
            </div>

            <!-- Botones -->
            <div class="d-flex justify-end">
              <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
              <v-btn color="success" :disabled="!formValid || saveDisabled" text depressed
                @click="!editar ? agregarCopago() : editarCopago()">Guardar
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Diálogo para inactivar un copago -->
    <v-dialog v-model="dialogoEstado" transition="dialog-bottom-transition" max-width="21rem" persistent>
      <v-card>
        <v-card-title class="encabezado">
          <span class="text-h6">¿Desea inactivar el copago?</span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="d-flex justify-end">
            <v-btn class="me-2" color="error" text @click="dialogoEstado = false">No</v-btn>
            <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapState } from "vuex";

const debounce = function debounce(fn, delay) {
  let timeoutID = null;
  return function () {
    clearTimeout(timeoutID);
    const args = arguments;
    const that = this;
    timeoutID = setTimeout(function () {
      fn.apply(that, args);
    }, delay);
  };
};

export default {
  name: "Copago",
  data() {
    return {
      regimenes: [
        { text: 'Contributivo', value: 'RC' },
        { text: 'Subsidiado', value: 'RS' },
      ],
      niveles: [1, 2, 3],
      dialogoCopago: false,
      buscar: {
        ano: '',
        nivel: '',
        regimen: 'RC',
      },
      cardCopago: {
        items: [],
        opciones: [5, 10, 15, 20],
        itemsPerPage: 5,
        page: 1,
        totalPage: 0
      },
      copago: {
        ano: new Date().getFullYear(),
        incremento: '',
        niveles: [
          { nivel: 1, topeEvento: '', topeAnual: '', evento: 11.50 },
          { nivel: 2, topeEvento: '', topeAnual: '', evento: 17.30 },
          { nivel: 3, topeEvento: '', topeAnual: '', evento: 23.00 },
        ],
        regimenSubsidiado: { topeEvento: '', topeAnual: '', evento: 10.00 }
      },
      saveDisabled: false,
      validateYear: '',
      validatePercentage: '',
      editar: false,
      copagoSeleccionado: {},
      dialogoEstado: false,
      eliminado: false,
      todosInactivos: false,
      rules: {
        required: [
          v => !!v || "Campo obligatorio.",
        ],
      },
    }
  },
  mounted() {
    this.lastmodifiedby = this.auth.username.toUpperCase();
    this.listarCopagos();
  },
  computed: {
    ...mapState(["auth"]),
    tituloDialogo() {
      return this.editar ? 'Editar' : 'Agregar';
    },
    formValid() {
      if (!this.copago.ano || !this.copago.incremento) {
        return false;
      }
      for (let nivel of this.copago.niveles) {
        if (!nivel.topeEvento || !nivel.topeAnual || !nivel.evento) {
          return false;
        }
      }
      if (!this.copago.regimenSubsidiado.topeEvento || !this.copago.regimenSubsidiado.topeAnual || !this.copago.regimenSubsidiado.evento) {
        return false;
      }
      return true;
    },
    rangoItems() {
      const start = (this.cardCopago.page - 1) * this.cardCopago.itemsPerPage + 1;
      let end = this.cardCopago.page * this.cardCopago.itemsPerPage;
      if (end > this.cardCopago.totalElements) {
        end = this.cardCopago.totalElements;
      }
      return `${start} - ${end}`;
    }
  },
  watch: {
    'buscar.regimen': function () {
      if (this.buscar.regimen === 'RS') {
        this.buscar.nivel = '';
      }
      this.listarCopagos();
      this.cardCopago.page = 1
    },
    'buscar.nivel': function () {
      this.listarCopagos();
      this.cardCopago.page = 1;
    },
    'buscar.ano': function () {
      this.listarCopagos();
      this.cardCopago.page = 1;
    },
    'cardCopago.itemsPerPage': function () {
      this.listarCopagos();
      this.cardCopago.page = 1;
    },
    'cardCopago.page': function () {
      this.listarCopagos();
    },
    'dialogoCopago': function () {
      if (this.dialogoCopago === true && this.todosInactivos) {
        this.validarAno();
      }
    },
    'copago.incremento': debounce(function () {
      if (this.copago.incremento && !this.editar) {
        this.validarIncremento();
      } else {
        this.validatePercentage = '';
      }
    }, 800),
    'copago.ano': function (newValue, oldValue) {
      if (newValue != oldValue && !this.editar) {
        this.clearCampos();
      }
    },
  },
  methods: {
    soloNumeros(event) {
      const key = event.key;
      if (!key.match(/^[0-9]+$/)) {
        event.preventDefault();
      }
    },
    puntoYNumeros(event) {
      const key = event.key;
      if (!key.match(/^[0-9.]+$/)) {
        event.preventDefault();
      }
    },
    listarCopagos() {
      this.$http.get(`msa-administration/api/copago/listar`, {
        params: {
          page: this.cardCopago.page - 1,
          size: this.cardCopago.itemsPerPage,
          regimen: this.buscar.regimen,
          nivel: this.buscar.nivel,
          ano: this.buscar.ano
        }
      }).then((response) => {
        this.cardCopago.items = response.data.content;
        this.cardCopago.totalPage = response.data.totalPages;
        this.cardCopago.totalElements = response.data.totalElements;
        this.copagosActivos();
      }).catch((error) => {
        console.log(error);
      });
    },
    async copagosActivos() {
      const response = await this.$http.get(`msa-administration/api/copago/copagosActivos`);
      this.todosInactivos = response.data.length === 0;
    },
    formatoPrecio(numero) {
      const formato = new Intl.NumberFormat('en-US');
      return formato.format(numero);
    },
    validarAno() {
      this.validateYear = '';
      let year = this.copago.ano;
      if (year) {
        if (year > new Date().getFullYear()) {
          this.validateYear = 'El año no debe ser mayor al actual.';
          this.saveDisabled = true;
        } else if (year.length < 4) {
          this.validateYear = 'Ingrese un año válido.';
          this.saveDisabled = true;
        } else {
          this.validarExistencia(year);
        }
      } else {
        this.saveDisabled = true;
      }
    },
    validarIncremento() {
      this.clearCampos();
      let percentage = this.copago.incremento;
      this.validatePercentage = '';
      if (percentage) {
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(percentage)) {
          this.validatePercentage = 'Ingrese un porcentaje válido.';
        } else {
          this.validatePercentage = '';
          this.calcularCopago();
        }
      } else {
        this.saveDisabled = true;
      }
    },
    validarExistencia(year) {
      this.$http.get(`msa-administration/api/copago/validar`, {
        params: {
          ano: year
        }
      }).then((response) => {
        if (response.data.length > 0) {
          this.validateYear = 'El copago ya existe.';
          this.saveDisabled = true;
        } else {
          this.validateYear = '';
          this.saveDisabled = false;
          this.calcularCopago();
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    calcularCopago() {
      if (this.validateYear === '' && this.copago.ano && this.copago.incremento && this.validatePercentage === '') {
        let year = Number(this.copago.ano) - 1;
        this.$http.get(`msa-administration/api/copago/calcular`, {
          params: {
            ano: year,
            incremento: this.copago.incremento
          }
        }).then((response) => {
          if (response.data && response.data.datos) {
            this.copago.niveles[0].topeEvento = response.data.eventoUno;
            this.copago.niveles[0].topeAnual = response.data.anualUno;
            this.copago.niveles[1].topeEvento = response.data.eventoDos;
            this.copago.niveles[1].topeAnual = response.data.anualDos;
            this.copago.niveles[2].topeEvento = response.data.eventoTres;
            this.copago.niveles[2].topeAnual = response.data.anualTres;

            this.copago.regimenSubsidiado.topeEvento = response.data.eventoSubsidiado;
            this.copago.regimenSubsidiado.topeAnual = response.data.anualSubsidiado;
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    agregarCopago() {
      this.saveDisabled = true;
      const copagos = [];

      this.copago.niveles.forEach(nivel => {
        copagos.push({
          regimenValue: "RC",
          nivelValue: nivel.nivel,
          evento: nivel.evento,
          topeEvento: nivel.topeEvento,
          topeAnual: nivel.topeAnual,
          ano: this.copago.ano,
          incremento: this.copago.incremento
        });
      });
      copagos.push({
        regimenValue: "RS",
        nivelValue: 1,
        evento: this.copago.regimenSubsidiado.evento,
        topeEvento: this.copago.regimenSubsidiado.topeEvento,
        topeAnual: this.copago.regimenSubsidiado.topeAnual,
        ano: this.copago.ano,
        incremento: this.copago.incremento
      })
      this.$http.post(`msa-administration/api/copago/guardar`, copagos)
        .then(() => {
          this.clear();
          this.listarCopagos();
        }).catch((error) => {
          console.log(error);
        });
    },
    buscarCopagos(item) {
      this.$http.get(`msa-administration/api/copago/buscar`, {
        params: {
          ano: item
        }
      }).then((response) => {
        this.copagoSeleccionado = response.data;
        this.copago.niveles[0].topeEvento = this.copagoSeleccionado.eventoUno;
        this.copago.niveles[0].topeAnual = this.copagoSeleccionado.anualUno;
        this.copago.niveles[1].topeEvento = this.copagoSeleccionado.eventoDos;
        this.copago.niveles[1].topeAnual = this.copagoSeleccionado.anualDos;
        this.copago.niveles[2].topeEvento = this.copagoSeleccionado.eventoTres;
        this.copago.niveles[2].topeAnual = this.copagoSeleccionado.anualTres;
        this.copago.regimenSubsidiado.topeEvento = this.copagoSeleccionado.eventoSubsidiado;
        this.copago.regimenSubsidiado.topeAnual = this.copagoSeleccionado.anualSubsidiado;
      }).catch((error) => {
        console.log(error);
      });
    },
    abrirDialogoEditar(item) {
      this.buscarCopagos(item.ano);
      this.copago.ano = item.ano;
      this.copago.incremento = item.incremento;
      this.editar = true;
      this.dialogoCopago = true;
    },
    editarCopago() {
      this.saveDisabled = true;
      const copagos = [
      {
        idCopago: this.copagoSeleccionado.idUno,
        regimenValue: "RC",
        nivelValue: 1,
        evento: this.copago.niveles[0].evento,
        topeEvento: this.copago.niveles[0].topeEvento,
        topeAnual: this.copago.niveles[0].topeAnual,
        ano: this.copago.ano,
        incremento: this.copago.incremento,
        lastmodifiedby: this.lastmodifiedby
      },
      {
        idCopago: this.copagoSeleccionado.idDos,
        regimenValue: "RC",
        nivelValue: 2,
        evento: this.copago.niveles[1].evento,
        topeEvento: this.copago.niveles[1].topeEvento,
        topeAnual: this.copago.niveles[1].topeAnual,
        ano: this.copago.ano,
        incremento: this.copago.incremento,
        lastmodifiedby: this.lastmodifiedby
      },
      {
        idCopago: this.copagoSeleccionado.idTres,
        regimenValue: "RC",
        nivelValue: 3,
        evento: this.copago.niveles[2].evento,
        topeEvento: this.copago.niveles[2].topeEvento,
        topeAnual: this.copago.niveles[2].topeAnual,
        ano: this.copago.ano,
        incremento: this.copago.incremento,
        lastmodifiedby: this.lastmodifiedby
      },
      {
        idCopago: this.copagoSeleccionado.idSubsidiado,
        regimenValue: "RS",
        nivelValue: 1,
        evento: this.copago.regimenSubsidiado.evento,
        topeEvento: this.copago.regimenSubsidiado.topeEvento,
        topeAnual: this.copago.regimenSubsidiado.topeAnual,
        ano: this.copago.ano,
        incremento: this.copago.incremento,
        lastmodifiedby: this.lastmodifiedby
      }
    ];
      this.$http.put(`msa-administration/api/copago/actualizar`, copagos)
        .then(() => {
          this.dialogoCopago = false;
          this.listarCopagos();
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    abrirDialogoEstado(item) {
      this.eliminado = item.eliminado;
      this.buscarCopagos(item.ano);
      this.dialogoEstado = true;
    },
    cambiarEstado() {
      this.eliminado = (this.eliminado == false) ? true : false;
      const estados = [
        {
          idCopago: this.copagoSeleccionado.idUno,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCopago: this.copagoSeleccionado.idDos,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCopago: this.copagoSeleccionado.idTres,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        },
        {
          idCopago: this.copagoSeleccionado.idSubsidiado,
          eliminado: this.eliminado,
          lastmodifiedby: this.lastmodifiedby
        }
      ]
      this.$http.put(`msa-administration/api/copago/inactivar`, estados)
        .then(() => {
          this.listarCopagos();
          this.dialogoEstado = false;
          this.clear();
        }).catch((error) => {
          console.log(error);
        });
    },
    clear() {
      this.dialogoCopago = false;
      this.dialogoEstado = false;
      setTimeout(() => {
        this.validateYear = '';
        this.editar = false;
        this.validatePercentage = '';
        this.saveDisabled = false;
        this.copago.incremento = '';
        this.copago.ano = new Date().getFullYear();
        this.clearCampos();
        if (this.$refs.copagoForm) {
          this.$refs.copagoForm.resetValidation();
        }
      }, 500);
    },
    clearCampos() {
      this.copagoSeleccionado = {};
      this.copago.niveles[0].topeEvento = '';
      this.copago.niveles[0].topeAnual = '';
      this.copago.niveles[0].evento = 11.50;

      this.copago.niveles[1].topeEvento = '';
      this.copago.niveles[1].topeAnual = '';
      this.copago.niveles[1].evento = 17.30;

      this.copago.niveles[2].topeEvento = '';
      this.copago.niveles[2].topeAnual = '';
      this.copago.niveles[2].evento = 23.00;

      this.copago.regimenSubsidiado.topeEvento = '';
      this.copago.regimenSubsidiado.topeAnual = '';
      this.copago.regimenSubsidiado.evento = 10.00;
    },
  },
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
.filtro {
  width: 25%;
}

.contenido {
  padding: 1rem;
  width: 100%;
  height: 85vh;
}

.encabezado {
  background-color: #1867c0;
  color: white;
}

.filas {
  width: 100% !important;
}

.nivel {
  display: flex;
  text-align: center;
  height: 40px;
}

.regimen {
  color: #0d47a1;
  display: flex;
  justify-content: center;
}

.linea {
  height: 1px;
  background-color: #0d47a1;
}

.mensaje {
  border-radius: 4px;
  background-color: #b4d1ff85;
}

::v-deep .elevation div table thead tr th {
  background-color: rgb(223, 223, 223) !important;
  font-weight: bold;
  color: black !important;
  height: 28px;
}

::v-deep .elevation div table tbody tr td {
  height: 25px;
}

.elevation {
  border: solid rgb(181, 178, 178) 0.5px;
  width: 90%;
}

.info-card {
  color: black;
  padding: 3px;
  font-weight: bold;
}

.paginado {
  font-size: small;
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 0;
}

.paginado button {
  border: none;
  margin: 0 7px;
}

.opciones {
  margin: 0;
  padding: 12px 0;
}

::v-deep .opciones .v-input__slot {
  width: 47.49px !important;
  font-size: small;
}

.contenedor-card {
  max-height: 73vh;
  overflow-y: auto;
}

.select {
  width: 4%;
}
</style>