import { render, staticRenderFns } from "./Copago.vue?vue&type=template&id=101e2bde&scoped=true"
import script from "./Copago.vue?vue&type=script&lang=js"
export * from "./Copago.vue?vue&type=script&lang=js"
import style0 from "./Copago.vue?vue&type=style&index=0&id=101e2bde&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "101e2bde",
  null
  
)

export default component.exports